import React, { useEffect, useState } from 'react';
import './style.css';
import { Header } from '../../Components/Header';
import { useParams } from 'react-router-dom';
import Cookies from 'universal-cookie';
import Time from '../SorteioOnline/Time';

const { REACT_APP_URL_API } = process.env;

const SorteioPainel = () => {
  const [data, setData] = useState(null);
  const [sorteio, setSorteio] = useState(null);
  const [showBotao, setShowBotao] = useState(0);
  const [showBotaoFalse, setShowBotaoFalse] = useState(0);
  const [sorteioPanel, setSorteioPanel] = useState(null);

  const { cliente_id, id } = useParams();
  const today = new Date();
  const dataFormatada = new Intl.DateTimeFormat('pt-BR', {
    day: '2-digit',
    month: 'long',
    year: 'numeric',
  }).format(today);

  const fetchData = async (endpoint, body, setter) => {
    const cookies = new Cookies();
    const token = cookies.get('csrftoken');
    const headers = new Headers({
      'Content-Type': 'application/json',
      'X-CSRFToken': token,
    });

    try {
      const response = await fetch(`${REACT_APP_URL_API}${endpoint}`, {
        method: 'POST',
        credentials: 'include',
        headers,
        body: JSON.stringify(body),
      });
      const result = await response.json();
      setter(result);
    } catch (error) {
      console.error(`Error fetching ${endpoint}:`, error);
    }
  };

  useEffect(() => {
    fetchData('/apiv2/index/', { client_id: cliente_id }, setData);
  }, [cliente_id]);

  useEffect(() => {
    fetchData('/apiv2/sorteio/', { client_id: cliente_id }, (json) => {
      setSorteio(json);
      const filteredResponse = json[0]?.response || [];
      const currentDate = dataFormatada.toLowerCase();

      setShowBotao(
        filteredResponse.filter(
          (item) =>
            currentDate === item.data_sorteio.slice(0, -7).toLowerCase() &&
            !item.sorteio_realizado &&
            item.controle[0] === 'show',
        ).length,
      );

      setShowBotaoFalse(
        filteredResponse.filter(
          (item) =>
            currentDate === item.data_sorteio.slice(0, -7).toLowerCase() &&
            item.sorteio_realizado &&
            item.controle[0] === 'show',
        ).length,
      );
    });
  }, [cliente_id, dataFormatada]);

  useEffect(() => {
    fetchData(
      '/apiv2/sorteio_screen_controller/',
      { client_id: cliente_id, sorteio_id: id },
      setSorteioPanel,
    );
  }, [cliente_id, id]);

  if (!data || !sorteio || !sorteioPanel) return null;

  const renderCard = (item, isLink = false) => (
    <div
      className=" card-painel container-card-sorteio box"
      style={{ maxHeight: '120px', padding: '5px' }}
    >
      {item.imagem ? (
        <img
          src={`${REACT_APP_URL_API}` + item.imagem}
          style={{ objectFit: 'scale-down' }}
          alt=""
        />
      ) : (
        <img
          src="https://vendamodaonline.com.br/wp-content/uploads/2020/08/sorteio-no-instagram-vale-a-pena.jpg"
          style={{ objectFit: 'scale-down' }}
          alt=""
        />
      )}
      <div className="conteudo-card conteudo-painel">
        <h2>{item.nome}</h2>
        <p>{item.data_sorteio}</p>
      </div>
    </div>
  );

  const renderSorteios = (filterFn, isLink = false) =>
    sorteio[0].response.filter(filterFn).map((item) =>
      isLink ? (
        <a key={item.id} href={`/${cliente_id}/${item.id}/painel`}>
          {renderCard(item, true)}
        </a>
      ) : (
        renderCard(item)
      ),
    );

  const currentDate = dataFormatada.toLowerCase();

  return (
    <div>
      <Header />
      <div className="sorteio_grid">
        {showBotao > 1 ? (
          <div className="container-proximo">
            <p style={{ color: 'green' }}>Próximos sorteios</p>
            {renderSorteios(
              (item) =>
                item.controle[0] === 'show' &&
                !item.sorteio_realizado &&
                currentDate === item.data_sorteio.slice(0, -7).toLowerCase(),
              true,
            )}
          </div>
        ) : (
          <div className="container-proximo div__none__proximo">
            <p style={{ color: 'green' }}>Próximos sorteios</p>
            <p>Não há nada aqui ainda.</p>
          </div>
        )}

        <div className="div-time">
          <Time />
        </div>

        {showBotaoFalse > 0 ? (
          <div className="container-encerrados ">
            <p style={{ color: 'red' }}>Sorteios Encerrados</p>
            {renderSorteios(
              (item) =>
                item.controle[0] === 'show' &&
                item.sorteio_encerrado &&
                currentDate === item.data_sorteio.slice(0, -7).toLowerCase(),
            )}
          </div>
        ) : (
          <div className="container-encerrados">
            <p style={{ color: 'red' }}>Sorteios Encerrados</p>
            <p>Não há nada aqui ainda.</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SorteioPainel;
